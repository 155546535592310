$fontStackPrimary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$fontPrimary: 'freight-sans-pro', $fontStackPrimary;

$fontStackSecondary: 'Georgia', serif;
$fontSecondary: 'GauthierFY', $fontStackSecondary;

$fontScript: 'Have Heart One';

$fontMonospace: 'Operator Mono Book', 'Operator Mono', 'Fira Code', 'Fira Mono', 'Courier', monospace;

@mixin fontPrimary {
  font-family: $fontStackPrimary;

  .font-freight-loaded & {
    font-family: $fontPrimary; } }

@mixin fontSecondary {
  font-family: $fontStackSecondary;

  .font-gauthier-loaded & {
    font-family: $fontSecondary; } }

@mixin fontScript {
  font-family: inherit;
  opacity: 0;
  transition: opacity .25s;

  .font-have-heart-loaded & {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $fontScript;
    font-smooth: always;
    opacity: 1; } }

@mixin script {
  @include fontScript;
  font-size: 22vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: .75em;
  text-align: center;
  transform: rotate(-2deg);
  transform-origin: 0 0; }
