.posts,
main > .post {
  padding: 0 0 4.5em; }

.post {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.animate-on-scroll {
    opacity: 0;
    transform: translateY(3em);
    transition: opacity .5s, transform .5s;

    &.animated {
      opacity: 1;
      transform: translateY(0); } }

  &__share {
    svg {
      vertical-align: middle;
      width: $baseline; }

    path {
      fill: $blue; } }

  &__header {
    flex: 0 0 100%;
    text-align: center;

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 32em; }

    @media (min-width: 32em) {
      text-align: left; } }

  &__link {
    color: $red;
    text-decoration-color: transparent;
    transition: text-decoration-color .5s ease;

    &:hover {
      text-decoration-color: $red; } }

  &__title {
    flex: 0 0 100%; }

  &__content {
    flex: 1 1 100%;
    line-height: $baseline;
    margin: 0 auto;
    max-width: 32em;
    width: 100%;

    h3 {
      font-size: 1em;
      letter-spacing: 1px;
      line-height: 1em;
      margin: #{$baseline * 2} 0 $baseline;
      text-transform: uppercase; } }

  &__meta {
    color: $grayTwo;
    display: block;
    margin: 0 0 $baseline;
    text-align: center;
    width: 100%;

    @media (min-width: 32em) {
      text-align: left;
      width: auto; }

    span, date {
      font-size: .875em; } }

  &__tags {
    display: block;
    flex: 0 0 100%;
    list-style: none;
    margin: 0 0 $baseline;

    @media (min-width: 32em) {
      flex-basis: auto;
      margin-left: auto; } }

  &__tag {
    display: inline;
    font-size: .875em;

    &:not(:first-child) {
      &::before {
        content: '•';
        margin: 0 1ch; } } } }

.posts {
  &__list {
    .post {
      margin: 0 0 #{$baseline * 3}; } }

  &__break {
    background: url('/img/icons/logo.svg') center / contain no-repeat;
    border: 0;
    display: block;
    height: .875em;
    margin: 0 0 #{$baseline * 4}; }

  &__pagination {
    margin: 0 auto;
    max-width: 32em;

    .page--post & {
      margin: 0 auto #{$baseline * 3}; }

    ul {
      align-items: center;
      display: flex;
      justify-content: space-between;
      list-style: none;
      position: relative;

      li {
        flex: 0 0 auto;
        max-width: 15em;
        text-align: center;

        &:nth-child(2) {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%); } } }

    &-link {
      align-items: center;
      display: flex;
      font-size: .875em;
      text-decoration: none;
      white-space: normal;

      &::before,
      &::after {
        align-self: center;
        color: $red;
        line-height: 1.625em; }

      &:hover {
        &::before,
        &::after {
          text-decoration: none; } }

      &--next {
        text-align: right;

        &::after {
          content: '→';
          margin-left: #{$baseline / 2}; } }

      &--previous {
        text-align: left;
        &::before {
          content: '←';
          margin-right: #{$baseline / 2}; } } } } }

.page--tag {
  .posts > h4 {
    margin: 0 auto 1.14286em;
    max-width: #{(1/1.3125) * 32em}; } }
