.intro {
  margin: 0 auto 4.5em;
  max-width: 32em;

  &__heading {
    @include script;
    color: $red;
    font-size: 3em;
    line-height: 1em;
    margin: 0 0 .5em;

    @media (min-width: 24em) {
      font-size: 3.75em; }

    @media (min-width: 42em) {
      font-size: 4.5em; }

    @media (min-width: 80em) {
      font-size: 5.25em; } }

  h4 {
    @include fontSecondary; } }

.homepage-section {
  margin: 0 auto;
  max-width: 32em;

  &__heading {
    @include script;
    font-size: 2.25em;
    line-height: 1em;
    margin: 1.5em 0 .5em;

    @media (min-width: 24em) {
      font-size: 3em; }

    @media (min-width: 42em) {
      font-size: 3.75em; }

    @media (min-width: 80em) {
      font-size: 4.5em; } }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none; }

  &__item {
    align-items: baseline;
    flex: 0 0 100%;
    line-height: $baseline;
    margin: 0 0 $baseline;

    @media (min-width: 32em) {
      flex: 0 0 calc(50% - #{$baseline}); }

    &:last-child {
      align-self: flex-end; }

    p {
      margin: 0; } }

  &__title {
    margin: 0;
    // font-size: 1.5em
    // line-height: #{(1 / 1.5) * $baseline}
    // margin: 0 auto 0 0

    // @media (min-width: 42em)
    //   font-size: 2.25em
 }    //   line-height: #{(1.35 / 1.5) * $baseline}

  &__link {
    color: $red;
    text-decoration: none;

    &[data-visited="true"] {
      color: $black;

      &::after {
        background: $black;
        border-radius: .15em;
        color: $white;
        content: '✓ Read';
        font-size: .625em;
        font-weight: $fontWeightNormal;
        letter-spacing: normal;
        margin-left: .25em;
        padding: 0 .25em;
        position: relative;
        vertical-align: top;
        z-index: 2; } } }

  &__meta {
    flex: 0 0 auto;
    margin-left: $baseline; }

  .button {
    align-self: flex-end;
    margin-right: auto; } }
