$oBlack: #050505;
$oRed: #c4102e;
$oWhite: #fff;

.page--origin {
  .header {
    background: $oRed;

    &__title {
      border-left-color: transparentize($oWhite, .9); }

    .logo--frame {
      fill: $oWhite; }

    .nav-toggle {
      circle {
        fill: $oWhite; } }

    &:not(.header--sticky) {
      display: none; } } }

.study__section {
  &--origin {
    background: $oWhite;
    color: $oBlack;

    .study__title {
      color: $oRed; }

    .study__icons {
      @include studyIcons {
        fill: $oRed; } }

    .image--slideshow {
      .slideshow__button {
        g {
          stroke: $oRed; } } }

    .study__feature-list {
      li {
        &::before {
          color: $oRed; } } }

    &.study__section--intro {
      background: $oBlack;

      .study__intro {
        background-color: $oBlack;
        background-image: url('/img/studies/origin/bg.jpg');
        background-position: center;
        background-size: cover; }

      .study__title {
        color: $oRed; }

      .study__client {
        color: $vfWhite; }

      .study__content {
        color: $vfWhite; }

      .study__link {
        background: $oRed;
        border-color: $oRed;
        color: $vfWhite; }

      .study__icons {
        @include studyIcons {
          fill: $oRed; } }

      .image--full-width,
      .image--slideshow {
        background: $oBlack;

        @media (min-width: 42em) {
          &::before {
            background: linear-gradient(to bottom, transparentize($oBlack, 1), $oBlack 66.66%, $oBlack); }

          &::after {
            background: linear-gradient(to top, transparentize($oBlack, 1), $oBlack 66.66%, $oBlack); } }

        .slideshow__button {
          g {
            stroke: $oRed; } } }

      .image__caption {
        @media (min-width: 64em) {
          background: $oBlack;

          &::before {
            background: linear-gradient(to bottom, transparentize($oBlack, 1), $oBlack 66.66%, $oBlack); }

          &::after {
            background: linear-gradient(to top, transparentize($oBlack, 1), $oBlack 66.66%, $oBlack); } } }

      blockquote {
        p {
          color: $oRed; } }

      .social {
        &__link {
          path {
            stroke: $oRed; } }

        &__likes {
          color: $oRed; } } }

    &.study__section--website {
      background: $vfYellow;
      color: $vfPlum;

      .study__title {
        color: $vfPlum; }

      .study__client {
        color: $vfPlum; }

      .study__icons {
        @include studyIcons {
          fill: $vfPlum; }

        .study__client {
          color: $vfWhite; } }

      .image--full-width,
      .image--slideshow {
        background-image: linear-gradient(to bottom, transparentize($vfYellow, 1), $vfYellow 15%, $vfYellow), linear-gradient(to top, transparentize($vfYellow, 1), $vfYellow 15%, $vfYellow);

        .slideshow__button {
          g {
            stroke: $vfPink; } } }

      .image__caption {
        @media (min-width: 64em) {
          background: $vfYellow;

          &::before {
            background: linear-gradient(to bottom, transparentize($vfYellow, 1), $vfYellow 66.66%, $vfYellow); }

          &::after {
            background: linear-gradient(to top, transparentize($vfYellow, 1), $vfYellow 66.66%, $vfYellow); } } }

      .social {
        &__link {
          path {
            stroke: $vfPink; } }

        &__likes {
          color: $vfPink; } } } } }

.studies__item--origin {
  .studies--cta & {
    background: url('/img/studies/origin/bg.jpg') center / cover no-repeat;

    .study__icons {
      @include studyIcons {
        fill: $oRed; } }

    .studies__title,
    .button {
      color: $oWhite; }

    .button {
      &::before {
        background-color: $oRed; } } } }

.image__pair {
  &--cards {
    first-of-type: {
      flex: 0 1 55.55%; }

    last-of-type: {
      flex: 0 1 44.45%; } } }

.study__type-sample {
  &--montserrat {
    span {
      color: $vfYellow;
      font-family: 'Montserrat';
      font-size: 5vw;
      font-weight: 700;
      line-height: 1em;
      text-transform: uppercase; }

    .study__type-sample-name {
      color: $vfPink; } }

  &--have-heart-one {
    span {
      color: $vfPink;
      font-family: 'Have Heart One';
      font-size: 10vw;
      line-height: 1em; }

    .study__type-sample-name {
      color: $vfYellow; } } }

.studies__item--origin {
  .studies__image {
    path {
      fill: $vfYellow; } } }
