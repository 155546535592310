.webmentions {
  font-size: .875em;
  line-height: $baseline;
  margin: 0 0 4.5em;

  &__wrapper {
    margin: 0 auto 4.5em;
    max-width: 32em; }

  &__list {
    list-style: none; }

  &__not-found {
    @include fontSecondary;
    font-style: italic; }

  &__item {
    border-bottom: 1px dashed $gray;
    margin-bottom: $baseline;
    padding-bottom: $baseline;

    &:last-child {
      border-bottom-width: 0; } } }

.webmention {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  &__author {
    a {
      align-items: center;
      display: flex; }

    &__photo {
      border-radius: .25em;
      margin-right: $baseline;
      width: #{$baseline * 2}; } }

  &__content {
    flex: 0 0 100%;
    order: 2;
    padding-left: #{$baseline * 3};

    p {
      margin-bottom: 0; } }

  &__meta {
    font-weight: $fontWeightBold;
    margin-left: auto; }

  &__source {
    background: url('/img/icons/link.svg') left center / contain no-repeat;
    display: inline-block;
    height: 1em;
    line-height: 1em;
    overflow: hidden;
    position: relative;
    text-indent: 1.5em;
    vertical-align: middle;
    white-space: nowrap;
    width: 1em;

    &:hover {
      margin-right: -.5em;
      overflow: inherit;
      width: 1.5em; } }

  &--like,
  &--repost {
    .webmention__content {
      flex: 1 1 auto;
      order: 0;
      padding-left: 0;

      a:last-of-type {
        display: none; } }

    .webmention__author {
      .p-name {
        display: none; } } } }
