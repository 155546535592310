.page--error {
  min-height: calc(100vh - .6em);
  padding: 0;

  main {
    align-items: stretch;
    display: flex;
    flex-direction: column;

    @media (min-width: 650px) {
      flex-direction: row; } }

  .page__wrapper,
  .page__listing {
    flex: 0 0 50%;
    padding: 4.5em 1.5em 0; }

  .page__wrapper {
    @media (min-width: 650px) {
      height: calc(100vh - .6em);
      overflow-y: auto; }

    @media (min-width: 42em) {
      padding: 4.5em 3em 0 between(1.5em, 4.5em, 42em, 80em); }

    @media (min-width: 80em) {
      padding: 4.5em 3em 0 4.5em; } }

  .page__listing {
    align-items: flex-end;
    display: flex;
    padding: 0 1.5em;

    @media (min-width: 650px) {
      padding-top: 0 1.5em; }

    @media (min-width: 42em) {
      padding: 0 between(1.5em, 4.5em, 42em, 80em) between(2.25em, 6.75em, 42em, 80em) 3em; }

    @media (min-width: 80em) {
      padding: 0 4.5em 6.75em 3em; }

    li {
      list-style: circle outside;
      margin: 0 0 #{$baseline / 2}; }

    a {
      @extend h3;
      @include fontSecondary; } }

  .page__image {
    flex: 0 0 50%;

    @media (min-width: 650px) {
      bottom: 0;
      height: calc(100vh - .6em);
      left: 50%;
      position: fixed;
      right: 0;
      top: 0; }

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%; } }

  .header {
    margin: 0;
    position: absolute; } }

.error {
  padding: 4.5em 0 0;

  &__code {
    color: $red; }

  &__title {
    @include fontSecondary; }

  .search__form {
    margin-bottom: 3em;

    @media (min-width: 42em) {
      margin-bottom: 4.5em; } } }
