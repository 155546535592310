.image {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: #{$baseline * 2} 0;

  @media (min-width: 42em) {
    margin: #{$baseline * 2} between(($paddingMin * -1), ($paddingMax * -1), 42em, 80em); }

  @media (min-width: 80em) {
    margin: #{$baseline * 2} #{$paddingMax * -1}; }

  &:first-child {
    margin-top: 0; }

  .post__content & {
    font-size: #{(1/1.125) * 1em}; }

  > img,
  > picture,
  > svg {
    display: block;
    flex: 0 0 100%;
    height: auto;
    margin-bottom: 0;
    width: 100%;

    &:first-of-type {
      &:nth-last-of-type(2n),
      &:nth-last-of-type(2n) ~ img,
      &:nth-last-of-type(2n) ~ picture,
      &:nth-last-of-type(2n) ~ svg {
        @media (min-width: 42em) {
          flex: 0 0 50%; } }

      &:nth-last-of-type(3n),
      &:nth-last-of-type(3n) ~ img,
      &:nth-last-of-type(3n) ~ picture,
      &:nth-last-of-type(3n) ~ svg {
        @media (min-width: 42em) {
          flex: 0 0 33.3333%; } } } }

  // Gifs have weird margins
  > img[src$='.gif'] {
    + .image__caption {
      margin-top: 1.125em; } }

  &--full-width,
  &--slideshow {
    background-color: transparent;
    background-image: linear-gradient(to bottom, $transparentWhite, $white 15%, $white), linear-gradient(to top, $transparentWhite, $white 15%, $white);
    background-position: top center, bottom center;
    background-repeat: no-repeat;
    background-size: 100% 15em, 100% 15em;
    position: relative;
    z-index: 1001;

    .image__caption {
      display: block;
      line-height: $baseline;
      margin-top: $baseline;
      position: static;
      width: calc(100vw - #{$baseline * 2});
      z-index: 0;

      &::after,
      &::before {
        content: none; } } }

  &--full-width {
    left: 50%;
    margin: #{$paddingMin * -1} 0 0;
    overflow: hidden;
    padding: #{$baseline * 2} 0;
    transform: translateX(-50%);
    width: 100vw;

    .image__caption {
      margin-left: $paddingMin;
      margin-right: $paddingMin; } }

  &--slideshow {
    left: 50%;
    margin: #{$baseline * 2} 0;
    overflow: hidden;
    padding: 0 1.5em;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;

    @media (min-width: 32em) {
      padding: 0 calc(50vw - 50%); }

    @media (min-width: 42em) {
      padding: 0 calc(50vw - 50% - 1.5em); }

    @media (min-width: 54em) {
      padding: 0 calc(50vw - 50% - 3em); }

    @media (min-width: 80em) {
      padding: 0 calc(50vw - 50% - 4.5em); }

    .image__caption {
      max-width: calc(100% - #{$baseline * 4});

      @media (min-width: 42em) {
        max-width: calc(100% - #{$baseline * 4}); }

      @media (min-width: 80em) {
        max-width: 30em; } } }

  &__caption {
    @include fontPrimary;
    color: $grayTwo;
    font-size: .75em;
    line-height: $baseline;
    margin: #{$baseline / 4} 0 0;
    max-width: 21em;

    strong {
      @include fontPrimary;
      font-feature-settings: 'tnum';
      font-style: normal; } }

  &__pair {
    align-items: stretch;
    display: flex;
    width: 100%;

    img, picture {
      align-self: stretch;
      flex: 1 1 auto;
      height: 100%;
      width: auto;

      img, source {
        height: 100%;
        width: 100%; } } }

  &__scroll-sync {
    height: 23.25em;
    overflow: hidden;
    width: 100%;

    > img,
    > picture {
      width: 100%; } } }

.slideshow {
  overflow: visible;
  position: relative;
  width: 100%;

  &__slides {
    align-items: center;
    display: flex;
    transition: transform .5s ease-in-out;
    width: 100%; }

  &__slide {
    box-shadow: $shadow;
    flex: 0 0 100%;
    // For some reason images within the slideshow have
    // a wierd margin around them. Removing the line-height
    // fixes it though
    line-height: 0;
    margin-right: $baseline;
    max-width: 28em;

    > img,
    > picture,
    > picture > source,
    > svg {
      display: block;
      width: 100%; } }

  &__button {
    background: transparent;
    bottom: #{$baseline * -1.625};
    cursor: pointer;
    display: inline-block;
    height: $baseline;
    left: auto;
    position: absolute;
    top: auto;
    transition: opacity .25s;
    width: $baseline;

    &--disabled {
      opacity: 0;
      pointer-events: none; }

    svg {
      height: 100%;
      width: 100%;

      g {
        stroke: $blue; } }

    &--next {
      right: 0;
      transform: rotate(180deg); }

    &--prev {
      right: 1.5em; } } }

picture {
  overflow: hidden;

  > source,
  > img {
    opacity: 1;
    transition: opacity .5s ease;
    width: 100%;

    &[data-src] {
      opacity: 0; } } }
