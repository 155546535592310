.search {
  &__form {
    margin: 0 0 4.5em; }

  &__results {
    list-style: none;

    &:empty {
      margin-top: -4.5em; } }

  &__result {
    margin: 0 0 3em;

    &--not-found,
    &--count {
      @include fontSecondary;
      color: $red;
      font-style: italic; } } }

.tags {
  padding: 4.5em 0;

  &__list {
    column-count: 2;
    list-style: none;

    @media (min-width: 24em) {
      column-count: 3; }

    @media (min-width: 36em) {
      column-count: 4; } }

  &__tag {
    font-size: .875em; } }
