input,
textarea,
select {
  appearance: none;
  border: 1px solid $gray;
  box-sizing: border-box;
  font: inherit;
  line-height: $baseline;
  padding: #{$baseline / 2};
  width: 100%; }

textarea {
  min-height: #{$baseline * 6}; }

button {
  appearance: none;
  background: $red;
  border: .15em solid $red;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-weight: 500;
  padding: .35em .75em;
  transition: background-color .375s ease, color .375s ease;
  width: 6em;

  &:hover {
    background-color: $white;
    color: $black; } }

.form {
  display: grid;
  grid-gap: $baseline;
  grid-template-columns: 1fr 1fr;

  &__group {
    display: grid;
    grid-column: 1 / 3;
    grid-gap: $baseline;
    grid-template-columns: 1fr 1fr; }

  &__description {
    grid-column: 1 / 3; }

  &__label {
    &--full-width {
      grid-column: 1 / 3; }

    &-text {
      display: block;
      font-size: .875em;
      font-weight: $fontWeightBold;
      margin: 0 0 #{$baseline / 4}; } }

  &__buttons {
    align-items: center;
    display: flex;
    grid-column: 1 / 3;
    justify-content: flex-end;

    span, button {
      margin-left: $baseline; }

    span {
      font-size: .875em; } } }
