$hfYellow: #ffc930;
$hfOrange: #eba721;
$hfOrange2: #f3813b;
$hfOrange3: #d96332;
$hfRed: #b22e37;
$hfWhite: #fff;
$hfTransparentOrange: rgba($hfOrange3, 0);
$hfTransparentOrange2: rgba($hfOrange3, .3);
$hfTransparentWhite: rgba($hfWhite, 0);
$hfTransparentWhite2: rgba($hfWhite, .3);
$hfTransparentYellow: rgba($hfYellow, 0);
$hfTransparentYellow2: rgba($hfYellow, .3);

.page--haresfoot {
  .header {
    background: $hfRed;

    &__title {
      border-left-color: transparentize($hfWhite, .7); }

    .logo--frame {
      fill: $hfWhite; }

    .nav-toggle {
      circle {
        fill: $hfWhite; } } } }

.study__section {
  &--haresfoot {
    background-color: $hfWhite;

    .study__intro {
      background-color: $hfWhite;
      background-image: linear-gradient(to bottom, $hfTransparentWhite 0%, $hfTransparentWhite 75%, $hfWhite 100%), url('/img/studies/haresfoot/mobile-bg.png');
      background-position: center;
      background-size: cover;

      @media (min-width: 42em) {
        background-image: linear-gradient(to bottom, $hfTransparentWhite 0%, $hfTransparentWhite2 75%, $hfWhite 100%), url('/img/studies/haresfoot/bg.png'); } }

    .study__title {
      color: $hfOrange2; }

    .study__icons {
      @include studyIcons {
        fill: $hfOrange2; } }

    &.study__section--intro {
      background: $hfOrange3;

      .study__intro {
        background-color: $hfOrange3;
        background-image: linear-gradient(to bottom, $hfTransparentOrange 0%, $hfTransparentOrange 75%, $hfOrange3 100%), url('/img/studies/haresfoot/mobile-bg.png');
        background-position: center;
        background-size: cover;

        @media (min-width: 42em) {
          background-image: linear-gradient(to bottom, $hfTransparentOrange 0%, $hfTransparentOrange2 75%, $hfOrange3 100%), url('/img/studies/haresfoot/bg.png'); } }

      .study__title,
      .study__content,
      .study__client {
        color: $white; }

      .study__content {
        a {
          &:hover {
            color: $hfYellow; } } }

      .study__link {
        background: $hfRed;
        border-color: $hfRed;
        color: $hfWhite; }

      .study__icons {
        @include studyIcons {
          fill: $white; } }

      .image--full-width,
      .image--slideshow {
        background-image: linear-gradient(to bottom, transparentize($hfOrange3, 1), $hfOrange3 15%, $hfOrange3), linear-gradient(to top, transparentize($hfOrange3, 1), $hfOrange3 15%, $hfOrange3);

        .slideshow__button {
          g {
            stroke: $hfYellow; } }

        .slideshow__slide {
          box-shadow: none;
          flex: 0 0 75%;
          margin-right: #{$baseline * 3};

          @media (min-width: 42em) {
            flex-basis: 15em; } } }

      .image__caption {
        color: $hfWhite; }

      .social {
        &__link {
          path {
            stroke: $white; } }

        &__likes {
          color: $white; } } }

    &.study__section--website {
      background-color: $hfYellow;

      .study__intro {
        background-color: $hfYellow;
        background-image: linear-gradient(to bottom, $hfTransparentYellow 0%, $hfTransparentYellow 75%, $hfYellow 100%), url('/img/studies/haresfoot/mobile-bg.png');
        background-position: center;
        background-size: cover;
        color: $black;

        @media (min-width: 42em) {
          background-image: linear-gradient(to bottom, $hfTransparentYellow 0%, $hfTransparentYellow2 75%, $hfYellow 100%), url('/img/studies/haresfoot/bg.png'); } }

      .study__title {
        color: $hfRed; }

      .study__icons {
        @include studyIcons {
          fill: $hfRed; } } } } }

.studies__item--haresfoot {
  .studies__image {
    path {
      fill: $hfOrange2; } }

  .studies--cta & {
    background: $hfOrange3 url('/img/studies/haresfoot/bg.png') center / cover no-repeat;

    .studies__image {
      path {
        fill: $hfWhite; } }

    .study__icons {
      @include studyIcons {
        fill: $hfWhite; } }

    .studies__title,
    .button {
      border-color: rgba($hfWhite, .3);
      color: $hfWhite;

      &:hover {
        border-color: $hfWhite;
        color: $hfWhite; }

      path {
        stroke: $hfYellow; } } } }
