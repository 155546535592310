.home-icon {
  &, &:hover {
    text-decoration: none; } }

.logo {
  width: #{$baseline * 1.5};

  &--frame,
  &--lens {
    transition: fill .5s; }

  &--frame {
    fill: $red; }

  a &:hover {
    .logo--frame {
      fill: $black; } }

  &--lens {
    fill: transparent; }

  .page--study &,
  .header--sticky &,
  .nav &,
  .contact__popup & {
    &--frame {
      fill: $white; } }

  //   &:hover
  //     .logo--frame
  //       fill: $white

  //     .logo--lens-left
  //       fill: transparentize($blue, .3)

  //     .logo--lens-right
 }  //       fill: transparentize($black, .7)
