.social {
  display: inline-flex;
  flex: 0 0 100%;
  font-size: .75em;
  justify-content: center;
  margin: $baseline auto 0;
  max-width: #{(1/.75) * 32em};

  @media (min-width: 32em) {
    justify-content: flex-start; }

  @media (min-width: 64em) {
    flex: 0 0 auto;
    flex-direction: column;
    margin: 0 0 0 #{$baseline * -1};
    position: sticky;
    top: between(3em, 7.5em, 42em, 80em);
    transform: translate(-.4em, .75em); }

  @media (min-width: 80em) {
    top: 7.5em; }

  svg {
    display: block;
    width: 1.5em;

    path {
      stroke-width: 2; } }

  &__likes,
  &__mentions {
    color: $black;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translate(.5em, -62.5%);
    width: 100%;

    @media (min-width: 51em) {
      transform: translate(.75em, -62.5%); } }

  &__link {
    flex: 0 0 auto;
    margin: 0 $baseline 0 0;
    position: relative;

    @media (min-width: 64em) {
      margin: 0 0 $baseline; }

    &--like,
    &--mention {
      margin-right: #{$baseline * 1.5};

      @media (min-width: 64em) {
        margin-right: 0; } }

    &--like {
      path {
        fill: transparent;
        stroke: $red;
        transition: fill .25s; } }

    &--liked {
      path {
        fill: $red; } }

    &--mention {
      path {
        fill: transparent;
        stroke: $green;
        transition: fill .25s; } }

    &--has-mentions {
      path {
        fill: $green; } }

    &--twitter {
      path {
        stroke: $twitterBlue; } }

    &--facebook {
      path {
        stroke: $facebookBlue; } } } }
