$lcWhite: #fff;
$lcBlue: #057281;
$lcOrange: #ef8019;
$lcBrown: #52302c;

.study__section {
  &--love-coffee {
    background: $white;

    .study__title {
      color: $lcBlue; }

    .study__icons {
      @include studyIcons {
        fill: $lcBlue; } }

    &.study__section--intro {
      background: $lcWhite;

      .study__intro {
        background-color: $lcWhite;
        background-image: linear-gradient(to bottom, transparentize($lcWhite, 1) 0%, $lcWhite 75%, $lcWhite 100%), url('/img/studies/love-coffee/bg.jpg');
        background-position: center;
        background-size: cover; }

      .study__title,
      .study__content {
        color: $black; }

      .study__client {
        color: $lcWhite; }

      .study__link {
        background: $lcBlue;
        border-color: $lcBlue;
        color: $lcWhite; }

      .study__icons {
        @include studyIcons {
          fill: $white; } } } } }

.studies__item--love-coffee {
  .studies__image {
    path {
      fill: $lcBlue; } } }
