$effRed: #aa314e;
$effRed2: #fc6473;
$effBlue: #113245;
$effWhite: #fff;
$effBlack: #000;
$effTransparentRed: rgba($effRed2, 0);
$effTransparentRed2: rgba($effRed2, .3);

.study__section {
  &--1855 {
    background: $white;

    .study__title {
      color: $effRed2; }

    .study__icons {
      @include studyIcons {
        fill: $effRed2; } }

    &.study__section--intro {
      background: $effRed2;

      .study__intro {
        background-color: $effRed2;
        background-image: linear-gradient(to bottom, $effTransparentRed 0%, $effTransparentRed2 75%, $effRed2 100%), url('/img/studies/1855/bg.jpg');
        background-position: center;
        background-size: cover; }

      .study__title,
      .study__content,
      .study__client {
        color: $white; }

      .study__link {
        background: $effBlue;
        border-color: $effBlue;
        color: $effWhite; }

      .study__icons {
        @include studyIcons {
          fill: $white; } } } } }

.studies__item--1855 {
  .studies__image {
    path {
      fill: $effRed; } } }
