@keyframes fadeInForm {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(4.5rem); }
  100% {
    display: grid;
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeOutForm {
  0% {
    display: block;
    opacity: 1;
    transform: translateY(0); }
  100% {
    display: none;
    opacity: 0;
    transform: translateY(-4.5rem); } }

.contact {
  margin-top: 4.5em;
  padding: 0 $paddingMin $paddingMax;
  text-align: center;

  @media (min-width: 42em) {
    padding: 0 between($paddingMin, $paddingMax, 42em, 80em) 4.5em; }

  @media (min-width: 80em) {
    padding: 0 $paddingMax 4.5em; }

  &__cta {
    color: $red;
    display: inline-block;
    text-decoration: none;
    transition: color .5s;

    &:hover {
      color: $black; }

    h3 {
      @include script;
      font-size: 4.5em;
      line-height: 1em;
      margin: 0;
      position: relative;
      text-transform: lowercase;

      @media (min-width: 80em) {
        font-size: 6em; } } }

  &__social {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: $baseline;

    .contact__popup & {
      justify-content: flex-start;
      margin-top: 0; }

    &-link {
      margin: 0 #{$baseline / 2};
      width: $baseline;

      .contact__popup &:first-child {
        margin-left: 0; }

      svg {
        height: $baseline;
        width: $baseline; }

      path {
        fill: $black;
        stroke: none;
        transition: fill .5s;

        .contact__popup & {
          fill: $white; } }

      &--twitter {
        &:hover {
          path {
            fill: $twitterBlue; } } }

      &--github {
        &:hover {
          path {
            fill: $githubPurple; } } }

      &--dribbble {
        &:hover {
          path {
            fill: $dribbblePink; } } }

      &--refind {
        &:hover {
          path {
            fill: $refindBlue; } } } } }

  &__popup {
    background: $red;
    bottom: 0;
    color: $white;
    left: 0;
    opacity: 0;
    overflow-y: auto;
    padding: 1.5em $paddingMin;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .375s ease;
    z-index: 0;

    @media (min-width: 42em) {
      padding: between(1.5em, $paddingMax, 42em, 80em) between($paddingMin, $paddingMax, 42em, 80em); }

    @media (min-width: 80em) {
      padding: $paddingMax; }

    &--open {
      opacity: 1;
      pointer-events: all;
      z-index: 9999; } }

  &__close {
    g {
      transition: stroke .5s; }

    &:hover {
      g {
        stroke: $black; } } }

  &__content {
    opacity: 0;
    transform: translateY(6rem);
    transition: opacity .375s .5s, transform .375s .5s;

    .contact__popup--open & {
      opacity: 1;
      transform: translateY(0);

      &.contact__content--hidden {
        opacity: 0;
        transform: translateY(6rem); } } }

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 4.5em; }

  &__title {
    @media (min-width: 64em) {
      font-size: 3em;
      line-height: 1em;
      margin: 0 0 1em; } }

  &__form {
    // display: none
    max-width: 36em;
    padding: 0 0 1.5em;

    @media (min-width: 42em) {
      padding-bottom: between(1.5em, 4.5em, 42em, 80em); }

    @media (min-width: 80em) {
      padding-bottom: 4.5em; }

    // .contact__popup[data-form] &
    //   animation: fadeInForm .375s ease forwards

    .form__description {
      a {
        color: $white;
        text-decoration: underline;

        &:hover {
          color: $black; } } }

    .form__input {
      background: rgba($black, .1);
      border-width: 0;
      color: $white;
      transition: background-color .5s, color .5s;

      &:focus {
        background-color: $white;
        color: $black; } }

    .form__group {
      &--hidden {
        display: none; } }

    .form__buttons {
      span {
        opacity: .7; }

      button {
        background: $white;
        border-color: $white;
        color: $black;

        &:hover {
          background: $red;
          color: $white; } } } } }
