@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(25vh); }

  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0); }

  100% {
    opacity: 0;
    transform: translateY(25vh); } }

.fadeInUp {
  opacity: 0;
  transform: translateY(-25%);

  &.animated {
    animation: fadeInUp 1s ease-out forwards; } }
