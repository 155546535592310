pre {
  background: #212733;
  box-sizing: border-box;
  color: $black;
  display: block;
  margin: $baseline 0;
  overflow-x: auto;
  padding: 1.25em 1.5em 1.5em;

  @media (min-width: 42em) {
    margin: $baseline between(($paddingMin * -1), ($paddingMax * -1), 42em, 80em); }

  @media (min-width: 80em) {
    margin: $baseline #{$paddingMax * -1}; } }

code {
  font: {
    family: $fontMonospace;
    size: .75em; }
  // Line height should be inherited, but for inline code blocks where it isn't, this maintains the baseline
  line-height: 0;
  padding: .25em;

  pre & {
    background: transparent;
    padding: 0; } }

// Generated by Pygments CSS Theme Builder - https://jwarby.github.io/jekyll-pygments-themes/builder.html
$highlightWhite: #ffefee;
$highlightYellow: #e7c547;
$highlightBlue: #53a6d9;
$highlightMagenta: #a37fd0;
$highlightGreen: #9ab500;
$highlightGray: #adb8c7;
$highlightOrange: #f2590c;

.highlight {
  // Base Style
  pre {
    color: $highlightWhite; }

  // Punctuation
  .p {
    color: $highlightWhite; }

  // Error
  .err {
    color: $highlightWhite; }

  // Base Style
  .n {
    color: $highlightYellow; }

  // Name Attribute
  .na {
    color: $highlightYellow; }

  // Name Builtin
  .nb {
    color: $highlightYellow; }

  // Name Class
  .nc {
    color: $highlightYellow; }

  // Name Constant
  .no {
    color: $highlightYellow; }

  // Name Decorator
  .nd {
    color: $highlightYellow; }

  // Name Entity
  .ni {
    color: $highlightBlue; }

  // Name Exception
  .ne {
    color: $highlightYellow; }

  // Name Function
  .nf {
    color: $highlightYellow; }

  // Name Label
  .nl {
    color: $highlightYellow; }

  // Name Namespace
  .nn {
    color: $highlightYellow; }

  // Name Other
  .nx {
    color: $highlightYellow; }

  // Name Property
  .py {
    color: $highlightMagenta; }

  // Name Tag
  .nt {
    color: $highlightBlue; }

  // Name Variable
  .nv {
    color: $highlightYellow; }

  // Name Variable Class
  .vc {
    color: transparent; }

  // Name Variable Global
  .vg {
    background-color: $highlightGreen;
    color: $highlightYellow; }

  // Name Variable Instance
  .vi {
    color: $highlightYellow; }

  // Name Builtin Pseudo
  .bp {
    color: $highlightYellow; }

  // Base Style
  .o {
    color: transparent; }

  // Operator Word
  .ow {
    color: transparent; }

  // Base Style
  .c {
    color: $highlightGray; }

  // Comment Multiline
  .cm {
    color: $highlightGray; }

  // Comment Preproc
  .cp {
    color: $highlightGray; }

  // Comment Single
  .c1 {
    color: $highlightGray; }

  // Comment Special
  .cs {
    background-color: $highlightMagenta;
    color: $highlightGray; }

  // Base Style
  .k {
    color: $highlightOrange; }

  // Keyword Constant
  .kc {
    color: $highlightOrange; }

  // Keyword Declaration
  .kd {
    color: $highlightOrange; }

  // Keyword Namespace
  .kn {
    color: $highlightOrange; }

  // Keyword Pseudo
  .kp {
    color: $highlightOrange; }

  // Keyword Reserved
  .kr {
    color: $highlightOrange; }

  // Keyword Type
  .kt {
    color: $highlightOrange; }

  // Base Style
  .l {
    color: $highlightMagenta; }

  // Literal Date
  .ld {
    color: transparent; }

  // Literal Number
  .m {
    color: $highlightMagenta; }

  // Literal Number Float
  .mf {
    color: $highlightMagenta; }

  // Literal Number Hex
  .mh {
    color: $highlightMagenta; }

  // Literal Number Integer
  .mi {
    color: $highlightMagenta; }

  // Literal Number Oct
  .mo {
    color: $highlightMagenta; }

  // Literal Number Integer Long
  .il {
    color: $highlightMagenta; }

  // Literal String
  .s {
    color: $highlightGreen; }

  // Literal String Backtick
  .sb {
    color: $highlightGreen; }

  // Literal String Char
  .sc {
    color: $highlightGreen; }

  // Literal String Doc
  .sd {
    color: $highlightGreen; }

  // Literal String Double
  .s2 {
    color: $highlightGreen; }

  // Literal String Escape
  .se {
    color: $highlightGreen; }

  // Literal String Heredoc
  .sh {
    color: $highlightGreen; }

  // Literal String Interpol
  .si {
    color: transparent; }

  // Literal String Other
  .sx {
    color: $highlightGreen; }

  // Literal String Regex
  .sr {
    color: $highlightGreen; }

  // Literal String Single
  .s1 {
    color: $highlightGreen; }

  // Literal String Symbol
  .ss {
    color: $highlightMagenta; }

  // Base Style
  .g {
    color: transparent; }

  // Generic Deleted
  .gd {
    color: transparent; }

  // Generic Emph
  .ge {
    color: transparent; }

  // Generic Error
  .gr {
    color: transparent; }

  // Generic Heading
  .gh {
    color: transparent; }

  // Generic Inserted
  .gi {
    color: transparent; }

  // Generic Output
  .go {
    color: transparent; }

  // Generic Prompt
  .gp {
    color: transparent; }

  // Generic Strong
  .gs {
    color: transparent; }

  // Generic Subheading
  .gu {
    color: transparent; }

  // Generic Traceback
  .gt {
    color: transparent; }

  // Other
  .x {
    color: transparent; }

  // Text Whitespace
  .w {
    color: transparent; } }

