.page--the-view-from-above {
  background: #1b1c1d;
  color: $white;

  .header:not(.header--sticky) {
    .logo--frame,
    .nav-toggle circle {
      fill: $red; }

    .logo:hover .logo--frame,
    .nav-toggle:hover circle {
      fill: $white; } }

  .post {
    @media (min-width: 32em) {
      padding-top: 20vw; }

    &__title {
      @include script;
      flex-basis: 60vw;
      font-size: 18vw;
      left: 50%;
      order: -1;
      position: relative;
      transform: translateX(-50%); }

    &__header-image {
      background: #1b1c1d;
      left: 50%;
      margin: 0;
      position: absolute;
      top: -15em;
      transform: translateX(-50%);
      width: 100vw;
      z-index: -1;

      &::after {
        background: linear-gradient(to bottom, rgba(#1b1c1d, .3) 0%, rgba(#1b1c1d, .3) 40%, rgba(#1b1c1d, 1) 90%, rgba(#1b1c1d, 1) 100%);
        bottom: calc(100% - 67vw);
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1; }

      > picture {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
        z-index: -1; } } }

  .contact {
    background: $white;
    left: 50%;
    margin-top: 0;
    padding-top: 6em;
    position: relative;
    transform: translateX(-50vw);
    width: 100vw; }

  .social {
    &__link {
      path {
        stroke: $white; } }

    &__likes {
      color: $white; } } }

.posts__list {
  .post--the-view-from-above {
    .post__header-image {
      display: none; } } }
