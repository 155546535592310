$baseline: 1.5em;
$fontWeightNormal: 300;
$fontWeightBold: 700;
$paddingMin: .75em;
$paddingMax: 4.5em;

@function between($from, $to, $fromWidth, $toWidth) {
  $slope: ($from - $to) / ($fromWidth - $toWidth);
  $base: $to - $slope * $toWidth;

  @return calc(#{$base} + #{100vw * $slope}); }

.screenreader-text {
  position: absolute;
  top: -99999em; }

html {
  font: $fontWeightNormal 100% / #{$baseline} $fontStackPrimary;
  font-feature-settings: 'kern', 'liga', 'onum'; }

body {
  @include fontPrimary;
  color: $black;
  font-size: 1em;
  height: 100%;
  line-height: $baseline;
  position: relative;

  @media (min-width: 42em) {
    font-size: calc(1em + (1.3125 - 1) * (100vw - 42em) / (80 - 42)); }

  @media (min-width: 80em) {
    font-size: 1.3125em; } }

%h1, %h2, %h3, %h4, %h5, %h6 {
  font-weight: $fontWeightBold;
  letter-spacing: -.0125em; }

%h1, %h2 {
  @include fontSecondary;
  font-size: 2.25em;
  font-weight: $fontWeightBold;
  letter-spacing: -.025em;
  line-height: #{(1.35 / 1.5) * $baseline};
  margin-bottom: #{(.6666666666666667 / 1.5) * $baseline}; }

%h1 {
  line-height: #{(1 / 1.5) * $baseline};
  margin-bottom: #{(.5 / 1.5) * $baseline};

  @media (min-width: 24em) {
    font-size: 3em; } }

h1 {
  @extend %h1; }

h2 {
  @extend %h2; }

%h3 {
  font-size: 1.5em;
  line-height: #{(1 / 1.5) * $baseline};
  margin-bottom: #{(1 / 1.5) * $baseline}; }

h3 {
  @extend %h3; }

%h4 {
  font-size: 1.3125em;
  line-height: #{(1.142857142857143 / 1.5) * $baseline};
  margin-bottom: #{(1.142857142857143 / 1.5) * $baseline}; }

h4 {
  @extend %h4; }

%h5 {
  font-size: 1.125em;
  line-height: #{(1.35 / 1.5) * $baseline};
  margin-bottom: #{(1.333333333333333 / 1.5) * $baseline}; }

h5 {
  @extend %h5; }

%h6 {
  font-size: 1em;
  line-height: $baseline;
  margin-bottom: $baseline; }

h6 {
  @extend %h6; }

p {
  &.lead {
    font-size: 1.5em; } }

p, ul, ol, dl, blockquote {
  margin-bottom: $baseline; }

ul {
  list-style: disc inside; }

ol {
  list-style: decimal inside; }

ul, ol {
  ul {
    list-style: circle inside;
    margin: {
      bottom: 0;
      left: $baseline; } }

  ol {
    list-style: lower-roman inside;
    margin: {
      bottom: 0;
      left: $baseline; } } }

em {
  font-style: italic; }

strong {
  font-weight: $fontWeightBold; }

small {
  font-size: .75em;
  line-height: .5em; }

hr {
  border: solid $black;
  border-width: 0 0 1px;
  height: 0;
  margin: calc(#{$baseline * 2} - 1px) auto #{$baseline * 2}; }

a {
  color: inherit;
  font-weight: $fontWeightBold;
  text-decoration: underline;
  text-decoration-skip: ink;
  transition: color .5s, text-decoration-color .5s;

  &:hover,
  &:focus {
    color: $red;
    text-decoration-color: transparent; } }

blockquote {
  @include fontSecondary;
  display: inline-block;
  font: {
    style: italic;
    weight: $fontWeightNormal; }
  font-feature-settings: 'kern', 'liga', 'clig', 'dlig', 'calt', 'onum';
  margin: $baseline 0;

  @media (min-width: 42em) {
    margin: $baseline between(-1.5em, -4.5em, 42em, 80em); }

  @media (min-width: 80em) {
    margin: $baseline #{$baseline * -3}; }

  p {
    margin: 0;
    quotes: '“' '”' '‘' '’';

    &:not(:last-of-type) {
      color: $red;
      font-size: 2.25em;
      line-height: #{(1.35 / 1.5) * $baseline};

      &:not(:nth-last-of-type(2)) {
        margin-bottom: #{(.6666666666666667 / 1.5) * $baseline}; }

      &::before {
        content: open-quote; }

      &::after {
        content: close-quote; } }

    &:last-of-type {
      margin: 0 0 $baseline; } }

  cite {
    @include fontPrimary;
    color: $grayTwo;
    display: block;
    font: {
      size: .75em;
      style: normal;
      weight: $fontWeightNormal; }

    &::before {
      content: '\2014';
      margin-right: 1ch; } } }

::selection {
  background: $black;
  color: $white; }
