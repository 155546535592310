.footer {
  background: $purple;
  color: $white;
  display: flex;
  justify-content: space-between;
  margin: 0 #{$paddingMin * -1};
  padding: .75em $paddingMin;

  @media (min-width: 42em) {
    margin: 0 between(($paddingMin * -1), ($paddingMax * -1), 42em, 80em);
    padding: .75em between($paddingMin, $paddingMax, 42em, 80em); }

  @media (min-width: 80em) {
    margin: 0 #{$paddingMax * -1};
    padding: .75em $paddingMax; }

  .page--study & {
    margin: 0; }

  span, a {
    font-size: .75em; }

  a {
    color: $white;
    text-decoration: underline; } }
