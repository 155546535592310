$vfYellow: #ffc857;
$vfPink: #d398bb;
$vfPurple: #9f87af;
$vfPlum: #704155;
$vfGreen: #a3c4bc;
$vfWhite: #fff;

.page--vixen-fitness {
  .header {
    background: $vfYellow;

    &__title {
      border-left-color: transparentize($vfPlum, .9); }

    .logo--frame {
      fill: $vfPlum; }

    .nav-toggle {
      circle {
        fill: $vfPlum; } } } }

.study__section {
  &--vixen-fitness {
    background: $vfWhite;
    color: $vfPlum;

    .study__title {
      color: $vfPink; }

    .study__icons {
      @include studyIcons {
        fill: $vfPink; } }

    .image--slideshow {
      .slideshow__button {
        g {
          stroke: $vfPink; } } }

    .image--branding {
      .slideshow__slide {
        box-shadow: none;

        svg {
          box-shadow: $shadow;

          &:not(:last-of-type) {
            margin: 0 0 $baseline; } } } }

    .study__feature-list {
      li {
        &::before {
          color: $vfPink; } } }

    &.study__section--intro {
      background: $vfPlum;

      .study__intro {
        background-color: $vfPlum;
        background-image: linear-gradient(to bottom, transparentize($vfPlum, 1) 0%, $vfPlum 75%, $vfPlum 100%), url('/img/studies/vixen-fitness/bg.png');
        background-position: center;
        background-size: cover; }

      .study__title {
        color: $vfYellow; }

      .study__client {
        color: $vfWhite; }

      .study__content {
        color: $vfWhite; }

      .study__link {
        background: $vfPink;
        border-color: $vfPink;
        color: $vfWhite; }

      .study__icons {
        @include studyIcons {
          fill: $vfYellow; } }

      .image--full-width,
      .image--slideshow {
        background: $vfPlum;

        @media (min-width: 42em) {
          &::before {
            background: linear-gradient(to bottom, transparentize($vfPlum, 1), $vfPlum 66.66%, $vfPlum); }

          &::after {
            background: linear-gradient(to top, transparentize($vfPlum, 1), $vfPlum 66.66%, $vfPlum); } }

        .slideshow__button {
          g {
            stroke: $vfYellow; } } }

      .image__caption {
        @media (min-width: 64em) {
          background: $vfPlum;

          &::before {
            background: linear-gradient(to bottom, transparentize($vfPlum, 1), $vfPlum 66.66%, $vfPlum); }

          &::after {
            background: linear-gradient(to top, transparentize($vfPlum, 1), $vfPlum 66.66%, $vfPlum); } } }

      blockquote {
        p {
          color: $vfYellow; } }

      .social {
        &__link {
          path {
            stroke: $vfYellow; } }

        &__likes {
          color: $vfYellow; } } }

    &.study__section--website {
      background: $vfYellow;
      color: $vfPlum;

      .study__title {
        color: $vfPlum; }

      .study__client {
        color: $vfPlum; }

      .study__icons {
        @include studyIcons {
          fill: $vfPlum; }

        .study__client {
          color: $vfWhite; } }

      .image--full-width,
      .image--slideshow {
        background-image: linear-gradient(to bottom, transparentize($vfYellow, 1), $vfYellow 15%, $vfYellow), linear-gradient(to top, transparentize($vfYellow, 1), $vfYellow 15%, $vfYellow);

        .slideshow__button {
          g {
            stroke: $vfPink; } } }

      .image__caption {
        color: $vfPlum;

        @media (min-width: 64em) {
          background: $vfYellow;

          &::before {
            background: linear-gradient(to bottom, transparentize($vfYellow, 1), $vfYellow 66.66%, $vfYellow); }

          &::after {
            background: linear-gradient(to top, transparentize($vfYellow, 1), $vfYellow 66.66%, $vfYellow); } } }

      .social {
        &__link {
          path {
            stroke: $vfPink; } }

        &__likes {
          color: $vfPink; } } } } }

.studies__item--vixen-fitness {
  .studies--cta & {
    background: url('/img/studies/vixen-fitness/bg.png') center / cover no-repeat;

    .study__icons {
      @include studyIcons {
        fill: $vfYellow; } }

    .studies__title,
    .button {
      border-color: rgba($white, .3);
      color: $white;

      &:hover {
        border-color: $white;
        color: $white; }

      &::before {
        color: $vfYellow; }

      path {
        stroke: $vfYellow; } } } }

.image__pair {
  &--cards {
    first-of-type: {
      flex: 0 1 55.55%; }

    last-of-type: {
      flex: 0 1 44.45%; } } }

.study__type-sample {
  &--montserrat {
    span {
      color: $vfYellow;
      font-family: 'Montserrat';
      font-size: 5vw;
      font-weight: 700;
      line-height: 1em;
      text-transform: uppercase; }

    .study__type-sample-name {
      color: $vfPink; } }

  &--have-heart-one {
    span {
      color: $vfPink;
      font-family: 'Have Heart One';
      font-size: 10vw;
      line-height: 1em; }

    .study__type-sample-name {
      color: $vfYellow; } } }

.studies__item--vixen-fitness {
  .studies__image {
    path {
      fill: $vfYellow; } } }
