$red: #f9415d;
$black: #020122;
$green: #00a878;
$blue: #6369d1;
$white: #fff;
$purple: #4a1942;
$gray: rgba($black, .05);
$grayTwo: #978090;
$transparentWhite: rgba($white, 0);

$shadow: 0 3px 15px rgba($black, .1);

$twitterBlue: #1da1f2;
$facebookBlue: #3b5998;
$githubPurple: #6e5494;
$dribbblePink: #ea4c89;
$refindBlue: #0090f2;
