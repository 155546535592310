html,
body {
  &.menu-open,
  &.contact-open {
    overflow: hidden;
    position: relative; } }

html {
  border-top: .75em solid $red;

  // @media (min-width: 42em)
 }  //   border-width: .75em

body {
  background: $white;
  opacity: 1;
  padding: 15em $paddingMin 0;
  transition: body .5s ease-out, opacity .5s ease-out;

  @media (min-width: 42em) {
    padding: 15em between($paddingMin, $paddingMax, 42em, 80em) 0; }

  @media (min-width: 80em) {
    padding: 15em $paddingMax 0; }

  .loading & {
    opacity: 0;
    transition: opacity .5s ease-in; } }

main {
  .page:not(.page--study) & {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .5s .5s ease-in, transform .5s .5s ease-out;

    .loading & {
      opacity: 0;
      transform: translateY(3em);
 } } }      // transition: opacity .5s ease-in, transform .5s ease-out

.turbolinks-progress-bar {
  background-color: $green;
  height: 1px; }

%script-background {
  &::after {
    @include script;
    bottom: -.25em;
    color: $red;
    content: 'Made in Southsea';
    left: 50%;
    pointer-events: none;
    position: absolute;
    transform: translateX(-51.5%);
    white-space: nowrap;
    z-index: 1; } }
