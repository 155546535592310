.page--study {
  padding: 0;

  .header {
    margin: 0;

    &:not(.header--sticky) {
      display: none; } } }

.studies {
  margin: 0 -1.5em;
  overflow: hidden;

  @media (min-width: 42em) {
    margin: 0 between(-1.5em, -4.5em, 42em, 80em); }

  @media (min-width: 54em) {
    margin-top: -25vw; }

  @media (min-width: 80em) {
    margin: -25vw -4.5em 0; }

  &__content {
    flex: 0 0 70%;
    margin-bottom: #{$baseline * .25};
    width: 70%; }

  .study__icons {
    text-align: left;

    @include studyIcons {
      fill: $black; } }

  &__image {
    align-items: flex-end;
    display: none;
    flex: 0 0 50vw;
    height: 50vw;
    width: 50vw;

    @media (min-width: 54em) {
      display: flex; }

    svg {
      margin-top: auto;
      width: 100%; } }

  &__title {
    @include fontSecondary; }

  &__item {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    padding: 0 1.5em 6em;
    pointer-events: none;
    position: relative;

    @media (min-width: 42em) {
      margin-bottom: between(4.5em, 0, 42em, 80em); }

    @media (min-width: 54em) {
      &:not(:first-of-type) {
        margin-top: -15vw; } }

    @media (min-width: 80em) {
      margin-bottom: 0; }

    .home-icon,
    .button {
      pointer-events: all; }

    @media (max-width: 54em) {
      .studies__content {
        flex: 0 0 100%;
        z-index: 1; }

      .studies__image {
        bottom: 10vw;
        position: absolute;
        right: -25vw;

        @media (min-width: 54em) {
          bottom: 3em; } }

      .studies__title {
        font-size: 2.25em;

        @media (max-width: 24em) {
          font-size: 1.5em; } } }

    @media (min-width: 42em) {
      padding: 0 between(1.5em, 4.5em, 42em, 80em) 4.5em; }

    @media (min-width: 54em) {
      .studies__content {
        margin-right: #{$baseline * 2}; }

      .studies__image {
        margin-right: -50vw; }

      &:nth-child(2n+1) {
        &:not(:only-child) {
          justify-content: flex-end;

          .studies__content {
            margin-left: #{$baseline * 2};
            margin-right: 0;
            order: 1; }

          .studies__image {
            margin-left: -50vw;
            margin-right: 0; } } } }

    @media (min-width: 80em) {
      padding: 0 4.5em 4.5em; } }

  &--cta {
    margin: 0 -1.5em;
    position: relative;
    z-index: 0;

    @media (min-width: 42em) {
      margin: 0 between(-1.5em, -4.5em, 42em, 80em); }

    @media (min-width: 80em) {
      margin: 0 -4.5em; }

    .page--study & {
      margin: 0; }

    .studies__item {
      padding: 3em 1.5em;

      @media (min-width: 42em) {
        padding: between(3em, 4.5em, 42em, 80em) between(1.5em, 4.5em, 42em, 80em); }

      @media (min-width: 80em) {
        padding: 4.5em; } }

    .studies__image {
      margin-top: -100vw; }

    .button {
      z-index: 5; } } }

.study {
  &__intro {
    padding: 4.5em 1.5em 0;
    position: relative;

    @media (min-width: 42em) {
      padding: 4.5em between(1.5em, 4.5em, 42em, 80em) 0; }

    @media (min-width: 80em) {
      padding: 4.5em 4.5em 0; } }

  &__section {
    .study__icons,
    .study__title {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .5s .5s ease-in, transform .5s .5s ease-out;

      .loading & {
        opacity: 0;
        transform: translateY(3em); } }

    &.animate-on-scroll {
      .study__icons,
      .study__title {
        opacity: 0;
        transform: translateY(3em); }

      &.animated {
        .study__icons,
        .study__title {
          opacity: 1;
          transform: translateY(0);
          transition: opacity .5s .5s ease-in, transform .5s .5s ease-out; } } } }

  &__icons {
    margin: 0 0 $baseline;
    text-align: center;
    text-decoration: none;

    .logo {
      height: 1.3125em; }

    .octicon--plus {
      height: 1em;
      margin: 0 #{$baseline / 2};
      width: 1em;

      polygon {
        fill: $white; } } }

  &__icon {
    height: auto;
    vertical-align: baseline;
    width: 1.875em;

    &--1855 {
      transform: scale(1.25);
      transform-origin: center; }

    path {
      fill: $white; } }

  &__title {
    color: $white;
    margin: 9em 0 3em;

    h1 {
      @include script;
      font-size: 18vw;
      margin: 0;
      text-align: center; } }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 1.5em 4.5em;

    @media (min-width: 42em) {
      padding: 0 between(1.5em, 4.5em, 42em, 80em) 4.5em; }

    @media (min-width: 80em) {
      padding: 0 4.5em 4.5em; }

    .post__content {
      flex: initial; } }

  &__type-sample {
    background: $white;
    left: 50%;
    margin: #{$baseline * 2} 0;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
    z-index: 1001;

    &-wrapper {
      height: 100%;
      overflow: hidden;
      text-align: center;
      width: 100%;

      > span {
        display: inline-block;
        left: 50vw;
        position: relative;
        transform: translateX(-50%);
        width: 125vw; } }

    @media (min-width: 42em) {
      &::after,
      &::before {
        content: '';
        display: inline-block;
        height: 3em;
        left: between(40em, 44.5em, 42em, 80em);
        margin-left: 12.5vw;
        pointer-events: none;
        position: absolute;
        right: 0; }

      &::before {
        background: linear-gradient(to bottom, $transparentWhite, $white 66.66%, $white);
        bottom: 100%; }

      &::after {
        background: linear-gradient(to top, $transparentWhite, $white 66.66%, $white);
        top: 100%; } } }

  &__feature-list {
    list-style: none;

    li {
      padding: $baseline 0 $baseline #{$baseline * 2};
      position: relative;

      @media (min-width: 42em) {
        margin: 0 #{$baseline * 3};
        padding: $baseline 0 $baseline #{$baseline * 3}; }

      &::before {
        @include fontScript;
        display: inline-block;
        float: left;
        font-size: 3em;
        left: .25em;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media (min-width: 42em) {
          font-size: 4.5em;
          left: 0; } }

      @for $var from 1 through 10 {
        &:nth-of-type(#{$var}) {
          &::before {
            content: '#{$var}. '; } } } } } }
