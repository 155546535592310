.nav {
  background: $red;
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 3em $paddingMin;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .375s;
  z-index: 10000;

  @media (min-width: 42em) {
    padding: between(3em, $paddingMax, 42em, 80em) between($paddingMin, $paddingMax, 42em, 80em); }

  @media (min-width: 80em) {
    padding: $paddingMax; }

  &::after {
    color: lighten($red, 5%); }

  &:target,
  &.nav--open {
    opacity: 1;
    pointer-events: initial;
    transition: opacity .375s;
    z-index: 9999; }

  &__container {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    left: 50%;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $paddingMax $paddingMin;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;

    @media (min-width: 42em) {
      flex-wrap: nowrap;
      padding: $paddingMax between($paddingMin, $paddingMax, 42em, 80em); }

    @media (min-width: 80em) {
      padding: $paddingMax; } }

  &__menu {
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    list-style: none;
    margin: 0 0 ($baseline * 2);
    z-index: 0;

    @media (min-width: 42em) {
      flex: initial;
      margin: 0; } }

  &__item {
    display: inline-block;
    margin: 0 0 #{$baseline / 2};
    opacity: 0;
    position: relative;
    text-align: center;
    transform: translateY(50%);
    transition: opacity .375s .5s, transform .375s .5s;

    .nav--open & {
      opacity: 1;
      transform: translateY(0);

      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          transition: opacity .25s #{.375s + ($i * .075s)}, transform .25s #{.375s + ($i * .075s)}; } } } }

  &__link {
    @include fontScript;
    color: $white;
    display: inline-block;
    font-size: 4.5em;
    font-weight: $fontWeightNormal;
    line-height: 1em;
    text-decoration: none;
    text-transform: lowercase;
    transform: rotate(-2deg);
    transition: color .5s;

    @media (min-height: 64em) {
      font-size: 6em; }

    &:hover,
    &:focus {
      color: $black;
      text-decoration: none; } }

  &__close {
    display: block;
    right: $paddingMin;

    @media (min-width: 42em) {
      right: between($paddingMin, $paddingMax, 42em, 80em); }

    @media (min-width: 80em) {
      right: $paddingMax; }

    svg {
      display: block;
      height: 1.75em;
      width: 1.75em;

      g {
        transition: stroke .5s; } }

    &:hover {
      g {
        stroke: $black; } } }

  &__home {
    display: inline-block;
    left: $paddingMin;
    width: auto;

    @media (min-width: 42em) {
      left: between($paddingMin, $paddingMax, 42em, 80em); }

    @media (min-width: 80em) {
      left: $paddingMax; } }

  &__home,
  &__close {
    position: absolute;
    top: 1.5em;
    z-index: 1;

    @media (min-width: 42em) {
      right: between($paddingMin, $paddingMax, 42em, 80em);
      top: between(1.5em, 3em, 42em, 80em); }

    @media (min-width: 80em) {
      right: $paddingMax;
      top: 3em; } }

  &__studies {
    flex: 0 0 100%;
    list-style: none;
    margin: 0;
    padding: 0 0 ($baseline * 2);
    text-align: center;

    @media (min-width: 42em) {
      flex: initial;
      padding: 0;
      text-align: left; }

    &-title {
      color: $white;
      font-size: .875em; } }

  &__study {
    &-item {
      .study__icons {
        font-size: .875em;
        margin: 0;

        @media (min-width: 42em) {
          text-align: left; } } }

    &-link {
      @extend %h2;
      color: $white;
      display: block;
      text-decoration: none;

      &:hover {
        color: $black; } }

    &-title {
      @include fontSecondary;
      font-weight: 700; } } }
