.header {
  margin: 0 #{$paddingMin * -1};
  padding: .75em $paddingMin;
  position: absolute;
  top: 4.5em;
  transform: translateY(0);
  transition: none;
  width: 100%;
  z-index: 9999;

  @media (min-width: 42em) {
    margin: 0 between(($paddingMin * -1), ($paddingMax * -1), 42em, 80em);
    padding: .75em between($paddingMin, $paddingMax, 42em, 80em); }

  @media (min-width: 80em) {
    margin: 0 #{$paddingMax * -1};
    padding: .75em $paddingMax; }

  &--sticky {
    background: $red;
    position: fixed;
    top: 0;
    transition: transform .5s;
    width: 100vw;

    circle {
      fill: $white; } }

  &--hidden {
    transform: translateY(-100%); }

  &__title {
    border-left: 1px solid transparentize($white, .7);
    color: $white;
    font-size: .75em;
    margin-left: #{(4/3) * $baseline};
    padding-left: #{(4/3) * $baseline};

    &:empty {
      display: none; } } }

.top-nav {
  align-items: center;
  display: flex;
  justify-content: flex-start; }

.nav-toggle {
  margin-left: auto;

  svg {
    height: 1.75em;
    vertical-align: middle;
    width: 1.75em;

    circle {
      transition: fill .5s; } }

  &:hover {
    circle {
      fill: $red;

      .header--sticky & {
        fill: $black; } } } }
