.projects {
  padding: 0 0 4.5em;

  &__filter {
    margin-bottom: $baseline;

    li {
      display: inline-block;
      list-style: none; }

    a {
      margin-right: $baseline;

      &.active {
        color: $red;
        font-weight: $fontWeightBold; } } }

  &__list {
    > ul {
      display: grid;
      font-size: .875em;
      grid-gap: $baseline;
      grid-template-columns: 1fr;
      list-style: none;

      @media (min-width: 48em) {
        grid-template-columns: 1fr 1fr; }

      @media (min-width: 72em) {
        grid-template-columns: 1fr 1fr 1fr; } } }

  &__project {
    border: 1px solid $gray;
    border-radius: .25em;
    box-sizing: border-box;
    line-height: $baseline;
    opacity: 0;
    padding: .75em;
    transform: translateY(3em);
    transition: border .25s, background .25s, opacity .5s .5s, transform .5s .5s;

    &.animated {
      opacity: 1;
      transform: translateY(0); }

    a {
      display: flex;
      flex-direction: column;
      font: inherit;
      height: 100%;
      text-decoration: none;
      transition: color .25s; }

    svg {
      path {
        fill: $red;
        transition: fill .25s; } }

    &:hover {
      background: $red;
      border-color: $red;

      a {
        color: $white;
        text-decoration: none; }

      svg {
        path {
          fill: $white; } }

      .projects__icon {
        color: $white; } }

    @media (min-width: 48em) {
      @for $i from 0 through 1 {
        &:nth-child(2n+#{$i+1}) {
          transition: border .25s, background .25s, opacity .5s #{$i * .125s}, transform .5s #{$i * .125s}; } } }

    @media (min-width: 72em) {
      @for $i from 0 through 2 {
        &:nth-child(3n+#{$i+1}) {
          transition: border .25s, background .25s, opacity .5s #{$i * .125s}, transform .5s #{$i * .125s}; } } }

    [data-description] {
      height: #{$baseline * 2}; } }

  &__meta {
    align-items: center;
    display: flex;
    list-style: none;
    margin: auto 0 0;

    > li {
      align-items: center;
      display: flex;
      font-feature-settings: 'lnum';
      margin-right: $baseline; } }

  &__icon {
    align-items: center;
    color: $red;
    display: flex;
    margin-right: #{($baseline / 4)};

    svg {
      height: #{$baseline / 1.5}; } }

  &__link {
    color: $black; } }

.project-highlights {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 #{$baseline * 4};

  &__column {
    &--left {
      flex: 0 0 100%;
      text-align: center;

      @media (min-width: 42em) {
        flex: 0 0 45%;
        position: sticky;
        text-align: left;
        top: between(1.5em, 4.5em, 42em, 80em); }

      @media (min-width: 80em) {
        top: 4.5em; }

      @media (min-width: 56em) {
        flex: 0 0 40%; } }

    &--right {
      flex: 0 0 100%;

      @media (min-width: 42em) {
        flex: 0 0 55%; }

      @media (min-width: 56em) {
        flex: 0 0 60%; } } }

  &__list {
    list-style: none;
    margin: #{$baseline * 3} 0 0; }

  &__item {
    display: flex;
    opacity: 0;
    transform: translateY(3em);
    transition: opacity .5s .5s, transform .5s .5s;

    &.animated {
      opacity: 1;
      transform: translateY(0); }

    &:not(:last-child) {
      margin: 0 0 #{$baseline * 3}; } }

  &__content {
    flex: 1 1 100%; }

  &__image {
    flex: 0 0 #{$baseline * 2};
    margin-right: $baseline;

    @media (min-width: 56em) {
      flex: 0 0 #{$baseline * 4};
      margin-right: #{$baseline * 2}; }

    img {
      border-radius: .25em;
      width: 100%; } } }
